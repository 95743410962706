<template>
  <div>
    <b-form @submit.prevent="handleAssignDriver">
      <b-form-group label="Select Driver">
        <vSelect
          v-model="selectedDriver"
          :options="options"
          placeholder="Assign driver for this order."
          class="style-chooser"
        />
      </b-form-group>

      <b-row class="my-2">
        <b-col cols="12" lg="4" class="mx-auto">
          <b-button
            :disabled="isLoading"
            variant="primary"
            type="submit"
            class="w-100"
          >
            <b-spinner v-if="isLoading" />
            <span v-else> Assign </span>
          </b-button>
        </b-col>
      </b-row>
    </b-form>
  </div>
</template>

<script>
import vSelect from 'vue-select';

export default {
  name: 'AssignDriverForm',
  components: {
    vSelect,
  },
  props: {
    order: {
      required: true,
      type: [Array, Object],
    },
  },
  data() {
    return {
      options: [],
      isLoading: false,
      selectedDriver: '',
    };
  },
  created() {
    this.getDrivers();
  },
  methods: {
    async getDrivers() {
      this.isLoading = true;
      const response = await this.$http.get('driver');
      if (!response.data.status) {
        this.$swal({
          title: 'Error!',
          text: 'Sorry. Something went wrong in your request. Please try again.',
          icon: 'error',
          showCancelButton: false,
          customClass: {
            confirmButton: 'btn-danger',
          },
        }).then((result) => {
          if (result.isConfirmed) {
            this.isLoading = false;
          }
        });
        return;
      }
      const { data } = response.data;
      this.options = data.map((i) => ({
        value: i.id,
        label: i.name,
      }));
      this.isLoading = false;
    },
    async handleAssignDriver() {
      this.isLoading = true;
      // const response = await this.$http.post('assign_driver', {
      //   driver_id: this.selectedDriver.value,
      //   order_id: this.order.id,
      // })
      // if (!response.data.status) {
      //   this.$swal({
      //     title: 'Error!',
      //     text: 'Sorry. Something went wrong in your request. Please try again.',
      //     icon: 'error',
      //     showCancelButton: false,
      //     customClass: {
      //       confirmButton: 'btn-danger',
      //     },
      //   }).then(result => {
      //     if (result.isConfirmed) {
      //       this.isLoading = false
      //     }
      //   })
      //   return
      // }

      this.$swal({
        // title: response.data.message,
        title: 'Test Message!',
        icon: 'success',
        text: `You have successfully assigned a driver for order ${this.order.id}`,
        confirmButtonText: 'OK',
        showCancelButton: false,
        customClass: {
          confirmButton: 'btn btn-success',
        },
        buttonsStyling: false,
      });
      this.isLoading = false;
      this.$emit('refresh');
    },
  },
};
</script>

<style lang="css">
.style-chooser .vs__search {
  padding: 2px 7px !important;
}

.style-chooser .vs__search::placeholder {
  color: #8e8e8e;
}
</style>

<style lang="scss">
@import 'vue-select/src/scss/vue-select.scss';
</style>
