<template>
  <b-container class="px-0">
    <MyTable
      title="Order Confirmation"
      :columns="columns"
      :rows="rows"
      :is-loading="isLoading"
    >
      <template v-slot="{ props }">
        <div v-if="props.column.field === 'id'">
          <b-button variant="primary" class="w-100">
            {{ props.row.id }}
          </b-button>
        </div>

        <div v-else-if="props.column.field === 'order_type'">
          <b-badge
            :variant="orderTypeColor(props.row.order_type)"
            class="w-100 badge-padding"
          >
            {{ props.row.order_type }}
          </b-badge>
        </div>

        <div v-else-if="props.column.field === 'order_date'">
          {{ $helpers.formatDate(props.row.order_placed_date) }}
        </div>

        <div v-else-if="props.column.field === 'actions'">
          <b-dropdown
            variant="link"
            toggle-class="text-decoration-none"
            no-caret
          >
            <template v-slot:button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="25"
                class="text-body align-middle mr-25"
              />
            </template>
            <b-dropdown-item @click="handleModal(props.row, 'assign-driver')">
              <feather-icon icon="TargetIcon" />
              Assign Driver
            </b-dropdown-item>
            <b-dropdown-item
              @click="
                $router.push({
                  name: 'order-summary',
                  params: { id: props.row.id },
                })
              "
            >
              <feather-icon icon="Edit2Icon" />
              Edit Order
            </b-dropdown-item>
            <b-dropdown-item @click="handleCancelOrder(props.row)">
              <feather-icon icon="XIcon" />
              Cancel Order
            </b-dropdown-item>
          </b-dropdown>
        </div>

        <div v-else>
          {{ props.formattedRow[props.column.field] }}
        </div>
      </template>
    </MyTable>

    <b-modal
      ref="modal"
      :title="modalTitle"
      hide-footer
      no-close-on-esc
      no-close-on-backdrop
    >
      <AssignDriverForm
        v-if="modalType === 'assign-driver'"
        :order="order"
        @refresh="handleRefresh"
      />
    </b-modal>
  </b-container>
</template>

<script>
import MyTable from '@/views/components/MyTable.vue';
import AssignDriverForm from '@/views/components/MyTasks/AssignDriverForm.vue';

export default {
  name: 'MyTask',
  components: {
    MyTable,
    AssignDriverForm,
  },
  data() {
    const columns = [
      {
        label: 'Order ID',
        field: 'id',
        sortable: true,
        thClass: 'text-nowrap',
        tdClass: 'align-middle',
      },
      {
        label: 'Order Type',
        field: 'order_type',
        sortable: false,
        thClass: 'text-nowrap',
        tdClass: 'text-center align-middle',
      },
      {
        label: 'Pick Up Postcode',
        field: 'pickup.postal_code',
        sortable: false,
        thClass: 'text-nowrap',
        tdClass: 'text-center align-middle',
      },
      {
        label: 'Pick Up Area',
        field: 'pickup.area',
        sortable: false,
        thClass: 'text-nowrap',
        tdClass: 'text-center align-middle',
      },
      {
        label: 'Drop Off Postcode',
        field: 'recipient.address_postcode',
        sortable: false,
        thClass: 'text-nowrap',
        tdClass: 'text-center align-middle',
      },
      {
        label: 'Drop Off Area',
        field: 'recipient.address_area',
        sortable: false,
        thClass: 'text-nowrap',
        tdClass: 'text-center align-middle',
      },
      {
        label: 'Order Date',
        field: 'order_date',
        sortable: false,
        thClass: 'text-nowrap',
        tdClass: 'text-center align-middle',
      },
      {
        label: 'Actions',
        field: 'actions',
        sortable: false,
        thClass: 'text-nowrap',
        tdClass: 'align-middle',
      },
    ];

    return {
      columns,
      rows: [],
      isLoading: false,
      modalType: null,
      order: [],
      reasonOptions: [
        { text: 'Vehicle Accident' },
        { text: 'Natural Disaster' },
        { text: 'Duplicate Order' },
        { text: "Wrong Recipient's Address" },
        { text: 'Client is not ready for Pick Up' },
        { text: 'IT Testing Order' },
      ],
    };
  },
  computed: {
    orderTypeColor() {
      return (val) => (val === 'Outlet' ? 'warning' : 'info');
    },
    modalTitle() {
      return this.modalType
        ? this.modalType.replaceAll('-', ' ').toUpperCase()
        : '';
    },
  },
  mounted() {
    this.getTasks();
  },
  methods: {
    async getTasks() {
      this.isLoading = true;
      const response = await this.$http.get('my_task');
      if (!response.status === 200) {
        this.$swal({
          title: 'Error!',
          text: 'Sorry. Something went wrong in your request. Please try again.',
          icon: 'error',
          showCancelButton: false,
          customClass: {
            confirmButton: 'btn-danger',
          },
        }).then((result) => {
          if (result.isConfirmed) {
            this.isLoading = false;
          }
        });
        return;
      }

      this.rows = response.data.data;
      this.isLoading = false;
    },
    async handleCancelOrder(order) {
      const { value: reason } = await this.$swal({
        title: 'Cancel Order',
        text: 'Please select reason for cancelling',
        input: 'select',
        inputOptions: this.reasonOptions.map((i) => i.text),
        inputPlaceholder: 'Select a reason',
        showCancelButton: true,
        reverseButtons: true,
        customClass: {
          confirmButton: 'btn-primary',
          cancelButton: 'btn-secondary',
        },
        inputValidator: (value) =>
          new Promise((resolve) => {
            if (value) {
              resolve();
            } else {
              resolve('Please select a reason.');
            }
          }),
      });

      if (reason) {
        this.$swal({
          title: 'Are you sure?',
          text: `You are about to cancel Order ID - ${order.id}. You won't be able to revert this!`,
          icon: 'warning',
          showCancelButton: true,
          reverseButtons: true,
          customClass: {
            confirmButton: 'btn-primary',
            cancelButton: 'btn-secondary',
          },
          confirmButtonText: 'Yes, cancel it!',
        }).then(async (result) => {
          if (result.isConfirmed) {
            await this.$http.post('cancel_order', {
              order_id: order.id,
              cancel_reason: this.reasonOptions[reason].text,
            });
            this.$swal({
              title: 'Order Cancelled!',
              text: `You have successfully cancelled Order ID - ${order.id}`,
              icon: 'success',
              showCancelButton: false,
              customClass: {
                confirmButton: 'btn-success',
              },
            }).then((afterSuccessfulCancel) => {
              if (afterSuccessfulCancel.isConfirmed) {
                this.handleRefresh();
              }
            });
          }
        });
      }
    },
    handleModal(order, type) {
      this.modalType = type;
      this.order = order;
      this.$refs.modal.show();
    },
    handleRefresh() {
      this.rows = [];
      this.getTasks();
      this.$refs.modal.hide();
    },
  },
};
</script>

<style scoped></style>
