import { render, staticRenderFns } from "./MyTask.vue?vue&type=template&id=4e6d3e33&scoped=true"
import script from "./MyTask.vue?vue&type=script&lang=js"
export * from "./MyTask.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4e6d3e33",
  null
  
)

export default component.exports